/*Typography*/
    .title, .custom-title, .text-brown {
        color: #877458 !important;
    }
    .main, .container, .custom-container {
        color: #f0f0f0 !important;
    }
    .description {
        color: #1f1f1f !important;
    }
/*END*/
/*Containers*/
    .custom-container {
        padding-top: 5px;
        padding-bottom: 40px;
    }
    .force-background, .force-background .container, .force-background .custom-container {
        background-color: #f0f0f0;
    }
/*END*/
.bn-home {
    width: 100%;
}

.padding-top-20 {
    padding-top: 20px;
}
.margin-top-30 {
    margin-top: 30px;
}
.left-20 {
    padding-left: 20px;
}