.tx-navy {
    color: navy !important;
}
.info .link-a {
    color: #1f1f1f !important;
}
.tx-red {
    color: #7c1313 !important;
}
div.icon-success i {
    font-size: 30px !important;
}
input.form-control {
    padding: 5px 0px 0px 5px;
    margin: 10px 0px 0px 10px;
}
textarea.form-control {
    padding: 5px 0px 0px 5px;
    margin: 10px 0px 0px 10px;
}
.contact-list {
    display: flex;
    flex-direction: column;
    align-content: left;
}
.contact-info .description {
    padding-left: 10px;
}
span.custom-title .fa-phone-alt {
    color: rgb(0, 2, 40) !important;
}

.group-description {
    margin: 0 !important;
    padding: 10px !important;
}
.group-description .title, .group-description .description {
    margin: 0 !important;
    padding: 0 !important;
}
.group-description #funcionamento i, .group-description #funcionamento strong {
    color: #fc9847;
    background: -webkit-linear-gradient(#f19112, #e9d520);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 20px;
}
.group-description #endereco i, .group-description #endereco strong {
    color: #fc4747;
    background: -webkit-linear-gradient(#d42d2d, #db5b3a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 20px;
}
.group-description p {
    padding-left: 20px;
    margin-left: 20px;
}